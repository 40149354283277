import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import './carousel.css'

const Carousel = (props) => {
  return (
    <div className={`carousel-gallery3 thq-section-padding ${props.rootClassName}`}>
      <div className="carousel-max-width thq-section-max-width">
        <div className="carousel-section-title">
          <span className="carousel-text thq-body-large">{props.content1}</span>
        </div>
        <div className="carousel-container">
          <div className="carousel-content">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              navigation
              pagination={{ clickable: true }}
              className="carousel-slider"
            >
              <SwiperSlide>
                <img
                  src={props.image3Src}
                  alt={props.image3Alt}
                  className="carousel-image2 thq-img-ratio-4-3"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={props.image4Src}
                  alt={props.image4Alt}
                  className="carousel-image3 thq-img-ratio-4-3"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

Carousel.defaultProps = {
  rootClassName: '',
  content1:
    'Tekintse meg rövid galériánkat, ahol bemutatjuk autósiskolánkat és oktatóit, valamint a használt járműveket.',
  image1Src: './images/oktatok_uj.JPG',
  image2Src: './images/autok.JPG',
  image3Src: './images/iroda3.JPG',
  image4Src: './images/motorok.jpg',
  image2Alt: 'Autósiskola autói',
  image3Alt: 'Irodánk',
  image4Alt: 'Autósiskola motorjai',
  image1Alt: 'Autósiskola oktatói',
}

Carousel.propTypes = {
  rootClassName: PropTypes.string,
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  image2Src: PropTypes.string,
  image3Src: PropTypes.string,
  image4Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Carousel
