import React, { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MapSection from '../Components/GoogleMap/Map';
import OddEvenCalendar from '../Components/Calendar/OddEvenCalendar';
import { AnimatePresence, motion } from 'framer-motion';
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";


const location = {
  address: 'Siófok, Zákonyi Ferenc köz, 8600',
  lat: 46.90639220773316,
  lng: 18.050912586507664,
}
46.90639220773316, 18.050912586507664


const Contact = () => {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Elérhetőségek'
  }, [])

  emailjs.init("slCDG9KliU1w0z5lI");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_i249h1i', 'template_ng3qo5c', e.target)
      .then((result) => {
        // console.log(result.text);
        toast.success('Email sikeresen elküldve!');
      }, (error) => {
        // console.log(error.text);
        toast.error('Email sikertelenül elküldve!');
      });
  };

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="contactPage"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.7 }}
        className="contact-page"
      >
        {/* Logo / Title */}
        <div className="text-center mb-4">
          <img
            className="contact-logo"
            src="./images/Feher_keret_nelkul_logo.png"
            alt="Balaton Autósiskola Logo"
            width="400"
          />
        </div>

        <div className="grid">
          {/* Left Column */}
          <div className="col-12 lg:col-6 mb-4">
            <Panel header="Bankszámlaszámunk" className="p-panel">
              <p>
                <strong>BALATON AUTÓSISKOLA KFT.</strong>
                <br />
                <b>12023101 01881804 00100009</b>
                <br />
                RAIFFEISEN BANK
                <br />
                Közleménybe: Név + mit utal (pl.: óra mennyisége)
              </p>
            </Panel>

            <Panel header="Elérhetőségek" className="p-panel mt-3">
              <h4 className="mt-0">Cím</h4>
              <p>
                Székhely: 8600 Siófok, Kristály utca 39.
                <br />
                Ügyfélfogadó iroda: 8600 Siófok, Kálmán Imre sétány 8.
                <br />
                Megközelítés: Víztorony felől, a Zákonyi Ferenc közben.
              </p>

              <h4>Nyitvatartás</h4>
              <p>
                hétfőtől péntekig → 10:00-13:00 - 13:30-16:30
              </p>

              <h4>Iroda telefonszáma</h4>
              <p>+36 204038020</p>

              <h4>Mobiltelefonszámok</h4>
              <p>
                +36 302169252
                <br />
                +36 706621010
                <br />
                +36 302172486
                {/* <br />
                +36 309311519 */}
                <br />
                +36 209643642
              </p>
            </Panel>

            <Panel header="Közösségi oldal" className="p-panel mt-3">
              {/* Embedded Facebook Page iframe */}
              <div className="facebook-iframe-container">
                <iframe
                  title="FacebookPage"
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100091974471166&tabs=timeline&width=340&height=480&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=456676587129644"
                  width="340"
                  height="480"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
            </Panel>
          </div>

          {/* Right Column */}
          <div className="col-12 lg:col-6">
            {/* <Panel className="p-panel">
              <OddEvenCalendar />
            </Panel> */}

            <Panel header="Kapcsolati űrlap" className="p-panel mt-3">
              <p>A *-al jelölt mező kitöltése kötelező.</p>
              <form ref={form} onSubmit={sendEmail} className="p-fluid">
                <div className="p-field mb-3">
                  <label htmlFor="name" className="form-label">
                    Név*
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="user_nev"
                    className="form-control"
                    required
                  />
                </div>

                <div className="p-field mb-3">
                  <label htmlFor="email" className="form-label">
                    Email cím*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="user_email"
                    className="form-control"
                    placeholder="pelda@gmail.com"
                    required
                  />
                </div>

                <div className="p-field mb-3">
                  <label htmlFor="subject" className="form-label">
                    Tárgy*
                  </label>
                  <textarea
                    id="subject"
                    name="targy"
                    rows="3"
                    className="form-control"
                    required
                  />
                </div>

                <button type="submit" className="btn btn-primary w-100">
                  Küldés
                </button>
              </form>
              <ToastContainer />
            </Panel>

            <Panel className="p-panel mt-3">
              <img
                src="./images/iroda3.JPG"
                alt="Iroda"
                className="img-fluid"
                style={{ maxHeight: "400px", objectFit: "cover" }}
              />
            </Panel>

            <Panel className="p-panel mt-3">
              <MapSection location={location} zoomLevel={17} />
            </Panel>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         <img className='logo' src='./images/Feher_keret_nelkul_logo.png' alt='' width='600' height='200' />
  //       </div>
  //       <div className='low-title'>
  //         <Fade bottom>
  //         <p className='low-titles'>
  //           Bankszámlaszámunk:
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;BALATON AUTÓSISKOLA KFT.<br />
  //           &emsp;<b>12023101 01881804 00100009</b><br />
  //           &emsp;RAIFFEISEN BANK<br />
  //           &emsp;Közleménybe Név + mit utal (pl.: óra mennyisége)
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='contact'>
  //           Elérhetőségek
  //         </p>
  //         <p className='low-titles'>
  //           Cím:<br />
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;Székhely: 8600 Siófok, Kristály utca 39.<br />
  //           &emsp;Ügyfélfogadó iroda: 8600 Siófok, Kálmán Imre sétány 8.<br />
  //           &emsp;Megközelítés: Víztorony felől, a Zákonyi Ferenc közben.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <OddEvenCalendar />
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-titles'>
  //           Nyitvatartás:
  //         </p>
  //         <p>
  //           &emsp;Páros héten: hétfőtől péntekig --{'>'} 9:00-13:00<br />
  //           &emsp;Páratlan héten: hétfőtől péntekig --{'>'} 12:30-16:30<br />
  //           &emsp;Minden szombaton --{'>'} 8:00-12:00
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-titles'>
  //           Iroda telefonszáma:<br />
  //         </p>
  //         <p>
  //           &emsp;+36 204038020
  //         </p>
  //         <p className='low-titles'>
  //           Mobiltelefonszámok:<br />
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;+36 302169252<br />
  //           &emsp;+36 706621010<br />
  //           &emsp;+36 302172486<br />
  //           &emsp;+36 309311519<br />
  //           &emsp;+36 209643642
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='contact'>
  //           Kapcsolati űrlap
  //         </p>
  //         <p className='low-title-content2'>
  //           A *-al jelölt mező kitöltése kötelező.
  //         </p>
  //         <form ref={form} onSubmit={sendEmail} className='low-title-content2'>
  //           <div className="mb-3">
  //             <label htmlFor="exampleFormControlInput1" className="form-label">Név*</label>
  //             <input type="text" className="form-control" id="exampleFormControlInput1" name='user_nev' required />
  //             <label htmlFor="exampleFormControlInput1" className="form-label">Email cím*</label>
  //             <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="pelda@gmail.com" name='user_email' required />
  //           </div>
  //           <div className="mb-3">
  //             <label htmlFor="exampleFormControlTextarea1" className="form-label">Tárgy*</label>
  //             <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" name='targy' required></textarea>
  //           </div>
  //           <div className="col-12">
  //             <button type="submit" className="btn btn-dark">Küldés</button>
  //             <ToastContainer />
  //           </div>
  //         </form>
  //         </Fade>
  //         <Fade bottom>
  //         <img src='./images/iroda3.JPG' className='iroda-kep2' alt='' width={900} height={700} />
  //         </Fade>
  //         <Fade bottom>
  //         <MapSection location={location} zoomLevel={17} />
  //         </Fade>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default Contact;