import { useEffect, useState } from "react";

const DeepLinkRedirect = () => {
  const [deepLink, setDeepLink] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const link = urlParams.get("target");

    if (link) {
      setDeepLink(link);
    }
  }, []);

  const handleRedirect = () => {
    if (deepLink) {
      window.location.href = deepLink;
    }
  };

  return (
    <div style={styles.container}>
      <h1>Irány a Kukucs!</h1>
      <h3>Kattints a gombra, ha vissza szeretnél menni az alkalmazásba.</h3>
      {deepLink && (
        <p>
          <button style={styles.button} onClick={handleRedirect}>
            Kattints ide a visszairányításhoz
          </button>
        </p>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  },

  button: {
    backgroundColor: "#1a5eb8",
    color: "black",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
  },
};

export default DeepLinkRedirect;
