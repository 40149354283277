import React, { useEffect } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from "primereact/card";

function AkoVsm() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - ÁKÓ - VSM'
  }, []);

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="akoVsmPage"
        className="ako-vsm-page"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.6 }}
      >
        <h1 className="text-center mb-4">ÁKÓ - VSM</h1>

        {/* VSM Section */}
        <Card className="mb-4" title="Vizsga Sikerességi Mutató (VSM)">
          <div className="flex flex-column gap-3">
            <img
              className="img-fluid vsm-image"
              src="./images/VSM_0205.png"
              alt="VSM diagram"
            />
            <div>
              <h5 className="mt-0 mb-3">Mutatók negyedévenként</h5>
              <div className="quarter-block">
                <span className="quarter-title">2023. 3. negyedév "B"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>53.85%</b>
                  </li>
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>41.86%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2023. 4. negyedév "B"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>75%</b>
                  </li>
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>51.06%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2024. 1. negyedév "B"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>56%</b>
                  </li>
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>44.07%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2024. 3. negyedév "A"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>83.33%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2024. 3. negyedév "B"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>56.10%</b>
                  </li>
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>39.51%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2024. 4. negyedév "A"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>100.00%</b>
                  </li>
                </ul>
              </div>
              <div className="quarter-block">
                <span className="quarter-title">2024. 4. negyedév "B"</span>
                <ul className="no-bullets">
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Elmélet: <b>61.22%</b>
                  </li>
                  <li>
                    <i className="pi pi-arrow-right"></i>&nbsp;Forgalom: <b>38.03%</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Card>

        {/* ÁKÓ Section */}
        <Card title="Átlagos Képzési Óraszám mutató (ÁKÓ)">
          <div className="flex flex-column gap-3">
            <img
              className="img-fluid ako-image"
              src="./images/ÁKÓ_0205.png"
              alt="ÁKÓ diagram"
            />
            <div>
              <h5 className="mt-0 mb-3">2024. 1. negyedév "B"</h5>
              <ul className="no-bullets">
                <li>
                  <i className="pi pi-arrow-right"></i>&nbsp;Gyakorlat: <b>125.20%</b>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="mt-0 mb-3">2024. 3. negyedév "B"</h5>
              <ul className="no-bullets">
                <li>
                  <i className="pi pi-arrow-right"></i>&nbsp;Gyakorlat: <b>132.93%</b>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="mt-0 mb-3">2024. 4. negyedév "B"</h5>
              <ul className="no-bullets">
                <li>
                  <i className="pi pi-arrow-right"></i>&nbsp;Gyakorlat: <b>128.34%</b>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         ÁKÓ - VSM
  //       </div>
  //       <div className='low-title'>
  //         <img className='VSM_kep' src='./images/VSM_0512.PNG' />
  //         <img className='AKO_kep' src='./images/ÁKÓ_0512.PNG' />
  //         <p className='low-titles'>
  //           Vizsga Sikerességi Mutató (VSM)
  //         </p>
  //         <p className='low-title-content2'>
  //           2023. 3. negyedév <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Elmélet: 53.85 % <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Forgalom: 41.86 %
  //         </p>
  //         <p className='low-title-content2'>
  //           2023. 4. negyedév <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Elmélet: 75 % <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Forgalom: 51.06 %
  //         </p>
  //         <p className='low-title-content2'>
  //           2024. 1. negyedév <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Elmélet: 56 % <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Forgalom: 44.07 %
  //         </p>
  //         <p className='low-titles'>
  //           Átlagos Képzési Óraszám mutató (ÁKÓ)
  //         </p>
  //         <p className='low-title-content2'>
  //           2024. 1. negyedév <br />
  //           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
  //           </svg>
  //           Gyakorlat: 125.20 %
  //         </p>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default AkoVsm;