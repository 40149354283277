import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import './csapat.css'

const Csapat = (props) => {
  const members = [
    {
      name: props.member1,
      img: props.member1Src,
      alt: props.member1Alt,
      content: props.member1Content,
    },
    {
      name: props.member2,
      img: props.member2Src,
      alt: props.member2Alt,
      content: props.member2Content,
    },
    {
      name: props.member3,
      img: props.member3Src,
      alt: props.member3Alt,
      content: props.member3Content,
    },
    {
      name: props.member5,
      img: props.member5Src,
      alt: props.member5Alt,
      content: props.member5Content,
    },
    {
      name: props.member6,
      img: props.member6Src,
      alt: props.member6Alt,
      content: props.member6Content,
    },
    {
      name: props.member7,
      img: props.member7Src,
      alt: props.member7Alt,
      content: props.member7Content,
    },
  ]

  return (
    <div className="csapat-team8 thq-section-padding">
      <div className="csapat-max-width thq-section-max-width">
        <div className="csapat-section-title">
          <div className="csapat-content">
            <h2 className="thq-heading-2">{props.heading1}</h2>
          </div>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          pagination={{ clickable: true }}
          navigation
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          {members.map((member, index) => (
            <SwiperSlide key={index}>
              <div className="csapat-card">
                <img
                  alt={member.alt}
                  src={member.img}
                  className="csapat-placeholder-image thq-img-ratio-1-1"
                />
                <div className="csapat-content01">
                  <div className="csapat-title">
                    <span className="csapat-text01 thq-body-small">{member.name}</span>
                  </div>
                  <span className="csapat-text02 thq-body-small">{member.content}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

Csapat.defaultProps = {
  member7: 'Kovács Adél',
  member7Content: 'Telefonszám: 06204038020',
  member7Alt: 'Kovács Adél',
  member7Src: './images/Adel.JPG',
  member5Content: 'Telefonszám: 06209643642',
  member2Alt: 'Horváth Zsolt',
  member5Alt: 'Tóth László',
  member6: 'Molnár Zsolt',
  member1Alt: 'Szabó Szabolcs',
  member2: 'Horváth Zsolt',
  member2Src: './images/Zsolt2.JPG',
  member6Alt: 'Molnár Zsolt',
  member3: 'Horváth Dániel',
  member6Src: './images/Molnar_Zsolt.jpeg',
  heading1: 'Ismerje meg autósiskolánk csapatát!',
  member6Content: 'Telefonszám: 06305310449',
  member5Src: './images/Toth_Laszlo.jpg',
  member3Src: './images/Daniel2.JPG',
  member1Src: './images/Szabi_profil.jpg',
  member1: 'Szabó Szabolcs',
  member2Content: 'Telefonszám: 06706621010',
  member5: 'Tóth László',
  member3Alt: 'Horváth Dániel',
  member1Content: 'Telefonszám: 06302169252',
  member3Content: 'Telefonszám: 06302172486',
}

Csapat.propTypes = {
  member5Content: PropTypes.string,
  member2Alt: PropTypes.string,
  member5Alt: PropTypes.string,
  member6: PropTypes.string,
  member1Alt: PropTypes.string,
  member2: PropTypes.string,
  member2Src: PropTypes.string,
  member6Alt: PropTypes.string,
  member3: PropTypes.string,
  member6Src: PropTypes.string,
  heading1: PropTypes.string,
  member6Content: PropTypes.string,
  member5Src: PropTypes.string,
  member3Src: PropTypes.string,
  member1Src: PropTypes.string,
  member1: PropTypes.string,
  member2Content: PropTypes.string,
  member5: PropTypes.string,
  member3Alt: PropTypes.string,
  member1Content: PropTypes.string,
  member3Content: PropTypes.string,
}

export default Csapat;