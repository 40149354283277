import React from 'react'

import PropTypes from 'prop-types'
import { Fade } from "react-awesome-reveal"


import './irodakozelites.css'

const Irodakozelites = (props) => {
  return (
    <div className="thq-section-padding">
      <Fade triggerOnce direction="up">
        <div
          data-thq-animate-on-reveal="true"
          className="irodakozelites-max-width thq-section-max-width"
        >
          <div className="irodakozelites-content">
            <h1 className="irodakozelites-text thq-heading-1">
              {props.heading1}
            </h1>
            <div className="irodakozelites-actions">
              <div className="irodakozelites-container">
                <svg viewBox="0 0 1024 1024" className="irodakozelites-icon">
                  <path d="M365.714 219.429h292.571v-73.143h-292.571v73.143zM164.571 219.429v731.429h-36.571c-70.286 0-128-57.714-128-128v-475.429c0-70.286 57.714-128 128-128h36.571zM804.571 219.429v731.429h-585.143v-731.429h73.143v-91.429c0-30.286 24.571-54.857 54.857-54.857h329.143c30.286 0 54.857 24.571 54.857 54.857v91.429h73.143zM1024 347.429v475.429c0 70.286-57.714 128-128 128h-36.571v-731.429h36.571c70.286 0 128 57.714 128 128z"></path>
                </svg>
                <span className="irodakozelites-text1">{props.text}</span>
                <svg viewBox="0 0 1024 1024" className="irodakozelites-icon2">
                  <path d="M214 470h596l-64-192h-468zM746 682q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM278 682q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM808 256l88 256v342q0 18-12 30t-30 12h-44q-18 0-30-12t-12-30v-44h-512v44q0 18-12 30t-30 12h-44q-18 0-30-12t-12-30v-342l88-256q12-42 62-42h468q50 0 62 42z"></path>
                </svg>
                <span className="irodakozelites-text2">{props.text1}</span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

Irodakozelites.defaultProps = {
  text: 'Irodánk: 8600 Siófok Kálmán Imre sétány 8.',
  heading1:
    'Jelentkezz online vagy személyesen ügyfélfogadó irodánkban:',
  text1: 'Megközelítés: Víztorony felől, Zákonyi Ferenc közben.',
}

Irodakozelites.propTypes = {
  text: PropTypes.string,
  heading1: PropTypes.string,
  text1: PropTypes.string,
}

export default Irodakozelites
