export const data1 = [
    { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "45 000 Ft" },
    { szemelyes_adat: "Gyakorlati képzés (10 +1 óra x  9 500 Ft/óra)", adatkezeles_celja: "104 500 Ft" },
    { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "11 400 Ft" },
    { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "7 800 Ft" },
    { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "7 800 Ft" },
    { szemelyes_adat: "Összesen", adatkezeles_celja: "176 500 Ft" },
  ]
  
export const data2 = [
    { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
    { szemelyes_adat: "Gyakorlati képzés (16 +1 x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
    { szemelyes_adat: "B-vel  gyakorlati óra (2 +1 x 9 500 Ft/óra)", adatkezeles_celja: "28 500 Ft" },
    { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "11 400 Ft" },
    { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "9 800 Ft" },
    { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "26 200 Ft" },
    { szemelyes_adat: "Összesen", adatkezeles_celja: "257 900 Ft" },
    { szemelyes_adat: "B-vel összesen", adatkezeles_celja: "124 900 Ft" },
  ]
  
  export const data3 = [
    { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
    { szemelyes_adat: "Gyakorlati képzés (16 +1 x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
    { szemelyes_adat: "2 évnél korábbi A1-el (12 +1 óra x 9 500 Ft/óra)", adatkezeles_celja: "123 500 Ft" },
    { szemelyes_adat: "2 évnél régebbi A1-el (8 +1 óra x 9 500 Ft/óra)", adatkezeles_celja: "85 500 Ft" },
    { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "11 400 Ft" },
    { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "9 800 Ft" },
    { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "26 200 Ft" },
    { szemelyes_adat: "Összesen", adatkezeles_celja: "257 900 Ft" },
    { szemelyes_adat: "Összesen 2 évnél fiatalabb A1-el", adatkezeles_celja: "159 500 Ft" },
    { szemelyes_adat: "Összesen 2 évnél régebbi A1-el", adatkezeles_celja: "181 900 Ft" },
  ]
  
  export const data4 = [
    { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
    { szemelyes_adat: "Gyakorlati képzés (26 +1 x 9 500 Ft/óra)", adatkezeles_celja: "256 500 Ft" },
    { szemelyes_adat: "2 évnél korábbi A1-el  (16 + 1 óra x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
    { szemelyes_adat: "2 évnél korábbi A2-vel (12 +1 óra x 9 500 Ft/óra)", adatkezeles_celja: "123 500 Ft" },
    { szemelyes_adat: "2 évnél régebbi A1-el (10 +1 óra x 9 500 Ft/ óra)", adatkezeles_celja: "104 500 Ft" },
    { szemelyes_adat: "2 évnél régebbi A2-vel (8 +1 óra x 9 500 Ft/óra)", adatkezeles_celja: "85 500 Ft" },
    { szemelyes_adat: "Kresz vizsga", adatkezeles_celja: "11 400 Ft" },
    { szemelyes_adat: "Járműkezelési rutin vizsga", adatkezeles_celja: "9 800 Ft" },
    { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "26 200 Ft" },
    { szemelyes_adat: "Összesen", adatkezeles_celja: "352 900 Ft" },
    { szemelyes_adat: "2 évnél korábbi A1-el ( csak gyakorlat)", adatkezeles_celja: "197 500 Ft" },
    { szemelyes_adat: "2 évnél korábbi A2-vel ( csak gyakorlat )", adatkezeles_celja: "159 500 Ft" },
    { szemelyes_adat: "2 évnél régebbi A1-el", adatkezeles_celja: "200 900 Ft" },
    { szemelyes_adat: "2 évnél régebbi A2-vel", adatkezeles_celja: "181 900 Ft" },
  ]
  
  export const data5 = [
    { szemelyes_adat: "Alapismeretek", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete", adatkezeles_celja2: "22 óra" },
    { szemelyes_adat: "Járművezetési gyakorlat", adatkezeles_celja: "Alapoktatás 4 óra, Főoktatás 6 óra", adatkezeles_celja2: "10 óra" },
    { szemelyes_adat: "Vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
    { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "27 óra" }
  ]
  
  export const data6 = [
    { szemelyes_adat: "Alapismeretek, KRESZ (B kategóriával 3 óra)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "16 óra" },
    { szemelyes_adat: "Járművezetési gyakorlat (B kategóriával 2 óra)", adatkezeles_celja: "Alapoktatás 4 óra, Főoktatás 10 óra", adatkezeles_celja2: "16 óra" },
    { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
    { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "29 óra" }
  ]
  
  export const data7 = [
    { szemelyes_adat: "Alapismeretek (0 óra 2 évnél fiatalabb A1-gyel, 3 óra 2 évnél régebbi A1-gyel)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete, Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "22 óra" },
    { szemelyes_adat: "Járművezetési gyakorlat (4+8 óra, min.180km 2 évnél fiatalabb A1-gyel, 2+6 óra, min.120km 2 évnél régebbi A1-gyel)", adatkezeles_celja: "Alapoktatás 6 óra, Forgalom 10 óra (min. 240 km)", adatkezeles_celja2: "16 óra" },
    { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
    { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "39 óra" }
  ]
  
  export const data8 = [
    { szemelyes_adat: "Alapismeretek (0 óra 2 évnél fiatalabb A1-gyel, 3 óra 2 évnél régebbi A1-gyel, 0 óra 2 évnél fiatalabb A2-vel, 3 óra 2 évnél régebbi A2-vel)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete, Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "22 óra" },
    { szemelyes_adat: "Járművezetési gyakorlat (6+10 óra, min.240km 2 évnél fiatalabb A1-gyel, 4+6 óra min.150km 2 évnél régebbi A1-gyel, 4+8 óra min.180km 2 évnél fiatalabb A2-vel, 2+6 óra min.120km 2 évnél régebbi A2-vel)", adatkezeles_celja: "Alapoktatás 10 óra, Forgalom 16 óra (min. 240 km)", adatkezeles_celja2: "26 óra" },
    { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
    { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "49 óra" }
  ]